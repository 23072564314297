import React, { useState } from 'react';
import { COLOR } from '@latitude/core/utils/constants';
import RatesAndFeesComponent from '@latitude/rates-and-fees';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';
import styled from 'styled-components';
import { Box } from '@latitude/box';

const heading = 'Rates, Fees and Conditions';

const RatesAndFeesColumn = () => {
  
  const [groupActiveIndex, setGroupActiveIndex] = useState(0);

  return (
    <div id="rates-and-fees" css={`background-color: #f8f8f8;
    padding: 30px 0;`}>
      <AnalyticsLocationProvider location={heading}>
        <RatesAndFeesComponent
          id="rates"
          css={`
            padding:0;
            button.link-block {
              background-color: ${COLOR.GREY5};
              cursor: pointer;
            }

            /* General Styles */
            .accordion-custom {
              margin: 10px;
            }
            .accordion__item-body{
              background-color: #f8f8f8; important!
            }
            .accordion__row {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              margin-bottom: 15px;
            }

            .accordion__item {
              width: 100%;
              margin: 0;
              padding: 5px;
            }
              .accordion-custom .accordion__row{
              padding: 5px 0 !important;
              }
            .accordion__row .accordion__item:nth-child(1) {
              width: 275px;
              flex-shrink: 0;
            }

            .accordion__row .accordion__item:nth-child(2) {
              flex-grow: 1; 
              text-align: right;
              padding-left: 10px;
            }
            /* Accordion content handling */
            .accordion__content {
              max-height: 0;
              overflow: hidden;
              transition: max-height 0.3s ease-out; /* Smooth opening/closing */
            }

            .accordion__content.open {
              max-height: 1000px; /* Arbitrary large value to allow content to expand */
            }

            .accordion__content.closed {
              max-height: 0;
            }
            div[class^="RatesAndFees"] {
              max-width: 1040px;
              margin:0px;
            }
            // div[class^="RatesListBranded"] {
            //   display:none;
            // }
            [class^="Heading__StyledComponent"] {
              padding:0px;
              text-align: left;
              margin:10px;
            }
            [class*="RatesList"]::before, [class*="RatesList"]::after{
              content: '';
              display: none;
            }
            [class*="StyledComponent"]{
              border: none;
            }
            [class*="RatesListBranded__Container"]{
              justify-content:start;
              @media (min-width: 576px) {
    .bMljS {
        min-width: 207px;
        max-width: 174px;
    }
            }
            .accordion{
            margin:5px;
            }
          `}
          title="Rates & Fees"
          ratesListProps={{
            data: [
              {
                largeText: '0%',
                smallText: 'for 6 months on everyday purchases $250 and over',
              },
              {
                largeText: '0%',
                smallText: 'on Interest Free Payment Plans',
              },
              {
                largeText: '0%',
                smallText: 'up to 55 days interest free<sup>1</sup> on everyday purchases',
              },
              {
                largeText: '27.99',
                showExtras: true,
                smallText: 'interest rate on everyday purchases',
              },
              {
                largeText: '$10.95',
                smallText: 'monthly account service fee (subject to change)',
              },
            ],
          }}
          accordionProps={{ 
            hasSmoothScroll: false,
            groupActiveIndex:0,
            setGroupActiveIndex:0,
            items: [
              {
                id: 'key-rates-fees',
                title: 'Key rates and fees',
                isOpen: true,
                content: (
                  <div className="accordion-custom">
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Monthly credit card fee</strong>
                      </p>
                      <p className="accordion__item">$10.95</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Minimum credit limit</strong>
                      </p>
                      <p className="accordion__item">$1,000</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Card purchase rate</strong>
                      </p>
                      <p className="accordion__item">
                      27.99% p.a.
                      </p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Cash advance rate</strong>
                      </p>
                      <p className="accordion__item">29.99% p.a.</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Expired promotional purchase rate</strong>
                      </p>
                      <p className="accordion__item">29.99% p.a.</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Late fee</strong>
                      </p>
                      <p className="accordion__item">
                      $45
                      </p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Additional cardholder fee</strong>
                      </p>
                      <p className="accordion__item">$0</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Payment handling fee</strong>
                      </p>
                      <p className="accordion__item">
                      $0 when you pay via the Latitude app, Latitude Service Centre, mail or direct debit
                      </p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Online statements</strong>
                      </p>
                      <p className="accordion__item">
                      $0 by opting to receive statements online. Simply login to the {' '}
                        <Link href="https://online.gemvisa.com.au/access/login">Latitude Service Centre</Link>.
                      </p>
                    </div>
                  </div>
                )
              },
              {
                id: 'other-information',
                title: 'Other rates and fees (if applicable)',
                content: (
                  <div className="accordion-custom">
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Cash advance fee</strong>
                      </p>
                      <p className="accordion__item">Greater of $4 or 3.5% of the <b>cash advance</b> amount</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>International transaction fee</strong>
                      </p>
                      <p className="accordion__item">3% of the Australian dollar value of the international transaction</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Other payment handling fees</strong>
                      </p>
                      <p className="accordion__item">
                      $1.95 when paying online using BPAY® $3.95 when paying in person at Australia Post
                      </p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Paper statement fee</strong>
                      </p>
                      <p className="accordion__item">$5 (avoidable by opting for online statements through {' '}
                        <Link href="https://online.gemvisa.com.au/access/login">Latitude Service Centre</Link>)</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Balance transfer fee</strong>
                      </p>
                      <p className="accordion__item">Up to 3% of the balance transfer amount</p>
                    </div>
                    <div className="accordion__row">
                      <p className="accordion__item">
                        <strong>Interest rate on balance transfers</strong>
                      </p>
                      <p className="accordion__item">
                      Please refer to the Balance Transfer interest rate, term and balance transfer fee set out in any offer presented to you 
                      </p>
                    </div>
                  </div>
                ),
              },
            ],
            
          }}
        />

      </AnalyticsLocationProvider>
      <Box className='keys-and-conditions-static' css={`
      width:1086px;
      margin: 0 auto;
      padding-top: 15px;
          `}>
            Before making a decision, please read <Link href="https://assets.latitudefinancial.com/legals/key-fact-sheet/gemvisa-au/kfs.html?">Key Facts sheet</Link> and <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-au/cou.pdf?">Conditions of use</Link> to ensure you're aware of all fees, charges and interest rates.
        </Box>
    </div>
  );
};

export default RatesAndFeesColumn;
