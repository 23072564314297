import React, { useState } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Accordion from './Accordion/Accordion';
import Section from '@latitude/section';
import { RATES_AND_FEES_COLOR } from '@/utils/constants';
import './_labRatesAndFeesColumn.scss';
import Button from '@/components/lab-components/Button';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import styled from 'styled-components';

const LabRatesAndFeesColumn = ({
  title,
  rates,
  accordions,
  backgroundColor,
  disclaimer,
  isRate
}) => {
  const isBlue = backgroundColor === RATES_AND_FEES_COLOR.BLUE;
  const RateItem = ({ number }) => (
    <div className={`rateItem ${isBlue && 'blue'}`}>
      {number && documentToReactComponents(number)}
    </div>
  );

  const getAccordionTriggerBgColor = ratesAndFeesBgColor => {
    if (ratesAndFeesBgColor === RATES_AND_FEES_COLOR.GREY) return 'white';
    return 'grey';
  };

  const [groupActiveIndex, setGroupActiveIndex] = useState(0);

  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, _children) => {
        if (node?.data?.target?.contentType?.sys?.id === 'simpleButton') {
          return (
            <Button
              theme="Link"
              rel="noopener noreferrer"
              disableDefaultFunnelTracking={true}
              trackEventData={{
                location: 'ratesandfees'
              }}
              href={node?.data?.target?.url}
              text={node?.data?.target?.text}
              isHomepageButton={false}
            />
          );
        }
      },
      [BLOCKS.DOCUMENT]: (node, children) => {
        const ChildrenGroupList = [];
        let textObject = {};
        
        for (let i = 0; i < children.length; i++) {
          if (children[i].type === 'h6') {
            textObject.boldText = children[i]?.props?.children;
          } else if (children[i].type === 'p') {
            textObject.normalText = children[i]?.props?.children;
          }

          if (
            children.length > 1 &&
            textObject.normalText &&
            (textObject.normalText.length > 1 ||
              textObject.normalText[0].length > 1)
          ) {
            ChildrenGroupList.push(textObject);
            textObject = {};
          } else if (
            children.length === 1 &&
            children[i].type !== 'h6' &&
            textObject.normalText &&
            (textObject.normalText.length > 1 ||
              textObject.normalText[0].length > 1)
          ) {
            return (
              <FlexRowContainer>
                <div>{textObject.normalText}</div>
              </FlexRowContainer>
            );
          }
        }

        return (
          <>
            {ChildrenGroupList.map((subChildren, index) => (
              <FlexContainer>
                <FlexItem key={index}>{subChildren.boldText}</FlexItem>
                <FlexItem key={'p-' + index}>{subChildren.normalText}</FlexItem>
              </FlexContainer>
            ))}
          </>
        );
      }
    }
  };
  const content = disclaimer?.content[0]?.content ? disclaimer?.content[0]?.content : [];
  return (
    <AnalyticsLocationProvider location={title}>
      <div className="labFeesAndRatesColumn" id="rates">
        <Section
          className={`feature-section common_tag_style ${
            backgroundColor === RATES_AND_FEES_COLOR.GREY
              ? 'bg-grey-light'
              : 'bg-white'
          }`}
        >
          {title && <div className="heading">{title}</div>}
          {isRate && 
          <div className={`rateList ${isBlue && 'blue'}`}>
            {rates?.map((rate, index) => (
              <RateItem key={index} {...rate} />
            ))}
          </div>}
          <Accordion
            titleBgColor={getAccordionTriggerBgColor(backgroundColor)}
            contentBgColor="white"
            isOpen
            groupActiveIndex={groupActiveIndex}
            setGroupActiveIndex={setGroupActiveIndex}
            className='lab-rates-and-fee-column'
            items={accordions.map(item => ({
              id: item.name,
              title: item.title,
              iconName: item?.icon,
              content: (
                <div style={{ padding: '3px 16px' }} className="rich-text">
                  {documentToReactComponents(item.content, renderOptions)}
                </div>
              )
            }))}
            hasSmoothScroll={false}
          />
          <div className='keys-and-conditions'>
            {content && content.map((node,index)=>{
              if (node.nodeType ==='text'){
                return <span key={index}>{node.value}</span>;
              }
              if (node.nodeType === 'hyperlink'){
                return <a href={node.data.uri} target='_blank'>{node.content[0].value}</a>
              }
            })}
          </div>
        </Section>
      </div>
    </AnalyticsLocationProvider>
  );
};

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 3px;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #dae3e7;
  & > div:nth-of-type(2) {
    text-align: right;
    @media (min-width: 760px) {
      width:70%;
`;

const FlexItem = styled.div`
  padding: 10px 0px;
  border-radius: 0;
  box-shadow: none;
  width: 46%;
  }
`;

const FlexRowContainer = styled.div`
  border: none;
  padding-top: 10px;
`;
export default LabRatesAndFeesColumn;
