// @flow

import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import { LinkBlock } from '@latitude/link-block';
import useOnScreen from '@latitude/core/hooks/useOnScreen';

const getOffsetHeight = (initialOffsetHeight = 0) => {
  let offsetHeight = initialOffsetHeight;
  const headerElement =
    typeof document !== 'undefined' && document.getElementsByTagName('header');
  const stickyNavElement =
    typeof document !== 'undefined' &&
    document.getElementsByClassName('sticky-navigation');

  if (headerElement && headerElement[0]) {
    offsetHeight += headerElement[0].offsetHeight;
  }

  if (stickyNavElement && stickyNavElement[0]) {
    offsetHeight += stickyNavElement[0].offsetHeight;
  }

  return offsetHeight;
};

const AccordionButton = (props) => {
  const {
    buttonId,
    fgColor,
    onClick,
    className,
    itemId,
    isOpen,
    iconName,
    title,
    activeIndex,
    isSticky,
    hasSmoothScroll
  } = props;
  const allowSmoothScrolling =
    hasSmoothScroll !== undefined ? hasSmoothScroll : true;
  const [buttonWasOpen, setButtonWasOpen] = useState(false);
  const buttonRef = useRef();
  const buttonOnScreen = useOnScreen(buttonRef, '-130px');
  const stickyNavigation =
    typeof document !== 'undefined' &&
    document.getElementsByClassName('sticky-navigation');

  useEffect(() => {
    if (activeIndex === -1 && buttonWasOpen && allowSmoothScrolling) {
      setTimeout(() => {
        let offsetHeight = getOffsetHeight(10);

        if (stickyNavigation && stickyNavigation[0]) {
          offsetHeight += stickyNavigation[0].offsetHeight;
        }

        if (
          buttonWasOpen &&
          !buttonOnScreen &&
          buttonRef &&
          buttonRef.current &&
          typeof window !== 'undefined'
        ) {
          const scrollYPosition =
            window.pageYOffset +
            buttonRef.current.getBoundingClientRect().top -
            offsetHeight;
          window.scrollTo({
            left: 0,
            top: scrollYPosition,
            behavior: 'smooth'
          });
          setButtonWasOpen(false);
        } else {
          setButtonWasOpen(false);
        }
      }, 100);
    }
  }, [buttonOnScreen, isOpen]);

  useEffect(() => {
    if (isOpen && !buttonWasOpen) {
      setButtonWasOpen(true);
    }
    if (isOpen && isSticky && buttonRef && buttonRef.current) {
      buttonRef.current.style.top = `${getOffsetHeight()}px`;
    }
  }, [isOpen]);

  return (
    <LinkBlock
      id={buttonId}
      noBorder
      fgColor={fgColor}
      onClick={onClick}
      className={classnames(
        className,
        isOpen && isSticky && 'accordion__button-fixed'
      )}
      flexHeight
      role="tab"
      aria-controls={itemId}
      aria-expanded={isOpen}
      iconName={iconName}
      chevronDirection={isOpen ? 'up' : 'down'}
      buttonRef={buttonRef}
    >
      {title}
    </LinkBlock>
  );
};

export default AccordionButton;