import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import { BUTTON_STYLE, BREAKPOINT } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';

import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { Strong } from '@/components/Text/Bold';

import Layout from '@/components/layout';
import { ImportantInformation } from '@latitude/important-information';
import { Text } from '@/components/Text';

import RatesAndFeesColumn from './_rates-and-fees-column';

import HowToApply from './_how-to-apply';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '@/components/PaymentOptions/PaymentOptions';

import { DigitalWallets } from '@latitude/digital-wallets';
import BrandedCallout from '@latitude/callout/BrandedCallout';

import { PageContext } from '@/context/PageContext';
import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import OfferBanner from '@/components/lab-components/OfferBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
// import LabRatesAndFeesColumn from '@/components/lab-components/LabRatesAndFeesColumn';
import GemMicrositeHeader from './_gem-microsite-header';
import { NotificationBanner } from '@latitude/banner';
import { FeaturesSlider } from '@latitude/features-slider';
import StandaloneText from '@/components/lab-components/StandaloneText';
import { COLOR } from '@/utils/constants';
import LabRatesAndFeesColumn from '@/components/lab-components/LabRatesAndFeesColumn';
import pageDataBackup from './data/gem-visa-page-backup.json'
import FeatureCardList from '@/components/FeatureCardList/featureCardList';
import ThemedHeroBanner from './_hero-rich-banner';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import {
  AppStoreBadge,
  AppStoreBadgeContainer
} from '@/components/AppStoreBadge/AppStoreBadge';
import imgHero from './images/mobile-app-hero.webp';
import { CARD_CODE, CARD_DATA } from '@/data/pages/credit-cards/card-offer';
import { AnalyticsContext } from 'latitude-analytics';

const APPLY_URL = 'https://cards.latitudefinancial.com/gem';

const schemaData = {
  "@context": "https://schema.org",
  "contactlessPayment": "true",
  "@type": "CreditCard",
  "annualPercentageRate": "27.99",
  "image": "https://assets.latitudefinancial.com/legals/key-fact-sheet/gemvisa-au/card-gem-visa.png",
  "url": "https://www.latitudefinancial.com.au/credit-cards/gem-visa/",
  "category": "Latitude Interest Free Credit Cards",
  "brand": { "@type": "Brand", "name": "Latitude Financial Services" },
  "amount": { "@type": "MonetaryAmount", "minValue": "1000", "currency": "AUD" },
  "name": "Latitude Gem Visa",
  "description":
    "Get an instant 0% Interest on everyday purchases of $250 or more and shop with 6-60 months interest free payment plans at thousands of stores.",
  "requiredCollateral": "$1000 minimum credit limit",
};

// Card data array
const cardData = CARD_DATA.filter((card) => card.code !== CARD_CODE.GEM)

function CreditCardsPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || pageDataBackup;
  /** - END - */
  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'gem-visa',
    page_pageType: 'product-details-page',
    page_siteSection: 'cards',
    page_siteSubsection: 'gem-visa',
    product_productid: ["CCAUGEM"]
  };

  return (
    <Layout location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 130px !important;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
          
          @media(max-width : ${BREAKPOINT.SM}) {
          margin-top: 105px !important;
          }
        `}
      >
        <Helmet>
          <link rel="canonical" href="https://www.latitudefinancial.com.au/credit-cards/gem-visa/"  hreflang="x-default" />
          <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          <title>Gem Visa Credit Card | Shop With 0% Interest Payment Plans</title>
          <meta name="description" content="Enjoy flexible finance and exclusive retail offers with the Latitude Gem Visa credit card. Unlock a smarter way to shop with Interest Free plans. Apply online" />
        </Helmet>
        <GemMicrositeHeader data={state.secondaryNavData} />
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
        )}
         {/* <ThemedHeroBanner /> */}

        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
            .sticky-navigation--fixed {
              top: 40px !important;
            }
          `}
        >
          <StickyNavigationBranded
            items={[
              {
                anchor: 'why-us',
                label: 'Why us?'
              },
              {
                anchor: 'rates-and-fees',
                label: 'Rates & Fees'
              },
              {
                anchor: 'payment-options',
                label: 'Payment Options'
              },
              {
                anchor: 'digital-wallets',
                label: 'Digital wallets'
              },
              {
                anchor: 'how-to-apply',
                label: 'How to apply'
              },
              {
                anchor: 'eligibility',
                label: 'Eligibility'
              }
            ]}
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        {state?.offerBannerData?.[0] && (
          <OfferBanner {...state?.offerBannerData[0]} />
        )}

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[0].heading}
            heading={state?.featureSliderData?.[0].heading}
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[0].featureCards}
            subheading={state?.featureSliderData?.[0].description}
            contentAlign={state?.featureSliderData?.[0].contentAlign}
            backgroundColor={COLOR.WHITE}
            css={`
              && {
                padding: 40px 15px 0;
              }
            `}
          />
        )}

        {state?.standaloneTextData?.[0] && (
          <StandaloneText 
            text={state?.standaloneTextData?.[0].text}
            align={state?.standaloneTextData?.[0].align}
            background={state?.standaloneTextData?.[0].background}
            styles={`
              ul {
                padding-left: 10px;
                margin: 0;
              }
              ul p {
                margin-bottom: 5px;
                padding: 0;
              }
              p {
                font-size: 16px;
                margin-top: 0;
                padding-left: 9px;
              }
              h5 {
                margin-top: 0;
              }
            `}
          />
        )}

        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFeesColumn isRate={true} {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFeesColumn />
        )}

        <HowToApply />

        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}

        <Section
          heading="Payment Options"
          id="payment-options"
          className="section--payment-options"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                titleHtml: 'Latitude App',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Online Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                titleHtml: 'Latitude Service Centre',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons css="justify-content:center;">
                        <Link
                          target="_blank"
                          button="secondary"
                          href="https://servicecentre.latitudefinancial.com.au/login"
                          data-trackid="payment-options-service-center-login-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Login
                        </Link>
                        <Link
                          target="_blank"
                          button="tertiary"
                          href="https://servicecentre.latitudefinancial.com.au/register"
                          data-trackid="payment-options-service-center-register-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Register
                        </Link>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click ‘Pay My Account’</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take 3 to 5 business days to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'bpay',
                title: 'BPAY',
                titleHtml:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude Gem Visa Biller Code (154799)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY on your
                        due date, you must complete the transaction before your
                        bank’s cut off time, otherwise the transaction may be
                        processed on the following day. Some BPAY payments can
                        take up to three days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            // tabContentMinHeight="385px"
            css={`
              && {
                .payment-options-tabs__content {
                  padding: 32px;
                }
              }
            `}
          />
        </Section>

        <Section
          heading="Still looking for other options? Explore our other credit cards"
          id="other-cards-section"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
        <FeatureCardList cardList = {cardData}/>
        </Section>

        <HeroBranded
          fastApply
              className="hero-control mobile-hero"
              css={`
                background: linear-gradient(128.6deg, #0146AA, #0162ED);
                @media (max-width: ${BREAKPOINT.MD}) {
                  .HeroContent {
                    background-color: transparent;
                  }
                }
              `}
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-start;
                    }
                  `}
                >
                  <img
                    src={imgHero}
                    alt=""
                    css={`
                      height: 260px;
                      width: auto;
                      position: absolute;
                      bottom: 0px;
                      @media (min-width: ${BREAKPOINT.MD}) {
                        height: 300px;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        height: 400px;
                        position: absolute;
                        bottom: 0px;
                        left: 160px;
                      }
                    `}
                  />
                </div>
              }
              title={
                <div css={`color: white;`}>Get the Latitude App.</div>
              }
              text={
                <div
                  css={`
                    padding: 8px 0 0;
                  `}
                >
                  <div
                    css={`
                      margin-bottom: 24px;
                      color: white;
                    `}
                  >
                    Manage your account and make repayments in just a few taps with
                    our mobile app.
                  </div>
                  <div
                    css={`
                      color: white;
                      margin: 12px 0;
                    `}
                  >
                    Download now!
                  </div>

                  <AnalyticsLocationProvider location="hero">
                    <AppStoreBadgeContainer>
                      <AppStoreBadge url="https://apps.apple.com/app/apple-store/id1373059866" />
                      <AppStoreBadge url="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp" />
                    </AppStoreBadgeContainer>
                  </AnalyticsLocationProvider>
                </div>
          }
        />
        
        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />

        <BrandedCallout
          line1={
            <React.Fragment>
              <span>For FAQs, useful information and quick and easy forms</span>
              <br />
            </React.Fragment>
          }
          cta={
            <React.Fragment>
              <p>
                <Link
                  button={BUTTON_STYLE.SECONDARY_INVERSE}
                  href="https://latitudefs.zendesk.com/hc/en-au"
                  trackId="callout-visit-our-help-centre"
                  trackEventData={{ location: "We're here to help" }}
                >
                  Visit our Help Centre
                </Link>
              </p>
            </React.Fragment>
          }
        />

        <ImportantInformation
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
}

export default CreditCardsPage;
